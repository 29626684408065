const enpointPDF = {
  local: 'http://localhost:5001/wedding-wish-89585/asia-southeast1/pdf',
  cloud: 'https://asia-southeast1-wedding-wish-89585.cloudfunctions.net/pdf'
}
const enpointExcel = {
  local: 'http://localhost:5001/wedding-wish-89585/asia-southeast1/excel',
  cloud: 'https://asia-southeast1-wedding-wish-89585.cloudfunctions.net/excel'
}
const enpointWishImage = {
  local: 'http://localhost:5001/wedding-wish-89585/asia-southeast1/wishImage',
  cloud: 'https://asia-southeast1-wedding-wish-89585.cloudfunctions.net/wishImage'
}
const enpointSendLineMessage = {
  cloud: 'https://asia-southeast1-wedding-wish-89585.cloudfunctions.net/line',
  local:  ''
}
const enpointPayment = {
  cloud: 'https://payment.photowish.com',
  //cloud: 'https://asia-southeast1-wedding-wish-89585.cloudfunctions.net/paymentsApis',
  local: 'http://localhost:5001/wedding-wish-89585/asia-southeast1/paymentsApis'
}
const enpointRemoveByDesignify = {
  cloud: 'https://asia-southeast1-wedding-wish-89585.cloudfunctions.net/removeDesignify-removeBGByDesignify',
  local:  ''
}
const enpointRemoveBG= {
  cloud: 'https://asia-southeast1-wedding-wish-89585.cloudfunctions.net/removeDesignify-removeBGByDesignify',
  local:  ''
}

const enpointInviteCard= {
  cloud: '',
  // local:  'http://localhost:3000/card'
  local:  'http://localhost:3000/card'
}

const enpointPhotobookServer = {
  cloud: 'https://book.photowish.com/wish',
  local: 'http://localhost:8080/photobook'
}

const enpointPhotobookApp = {
  cloud: 'http://wishbook.photowish.com',
  local: 'http://localhost:8080/photobook'
}
const enpointWishApp = {
  local: 'http://localhost:8080/wish',
  cloud: 'https://book.photowish.com/wish'
}

const enpointPresentSlide = {
  cloud: 'https://photowish-present.web.app',
  local: 'http://localhost:3002'
}

const enpointSendMail = {
  cloud: 'https://asia-southeast1-wedding-wish-89585.cloudfunctions.net/sendMail',
  local: 'http://localhost:5001/wedding-wish-89585/asia-southeast1/sendMail'
}

const enpointPrewedding = {
  cloud: 'https://asia-southeast1-wedding-wish-89585.cloudfunctions.net/prewedding',
  local: 'http://127.0.0.1:5001/wedding-wish-89585/asia-southeast1/prewedding'
}


const endpointCloudfareApi = {
  cloud:'https://deleteimage-cloudflaredeleteimage-m4gzben4za-as.a.run.app',
  local: 'https://deleteimage-cloudflaredeleteimage-m4gzben4za-as.a.run.app'
}

const endpointSendOTP = {
  cloud:'https://sendotp-sendotp-m4gzben4za-as.a.run.app',
  local: 'https://sendotp-sendotp-m4gzben4za-as.a.run.app'
}

const endpointDownloadWishes = {
  cloud:"https://downloadwishes-downloadwishes-m4gzben4za-as.a.run.app",
  local: "https://downloadwishes-downloadwishes-m4gzben4za-as.a.run.app"
}

const endpointDownloadPhotos = {
  cloud: "https://downloadoriginalphotos-downloadoriginalimages-m4gzben4za-as.a.run.app",
  local: "https://downloadoriginalphotos-downloadoriginalimages-m4gzben4za-as.a.run.app"
}

const endpointDownloadPromptpay= {
  cloud: "https://downloadreportpromptpay-downloadreportpromptpay-m4gzben4za-as.a.run.app",
  local: "https://downloadreportpromptpay-downloadreportpromptpay-m4gzben4za-as.a.run.app"
}



export {
  enpointPDF,
  enpointExcel,
  enpointWishImage,
  enpointSendLineMessage,
  enpointPayment,
  enpointRemoveByDesignify,
  enpointRemoveBG,
  enpointInviteCard,
  enpointPhotobookServer,
  enpointPhotobookApp,
  enpointWishApp,
  enpointPresentSlide,
  enpointSendMail,
  enpointPrewedding,
  endpointCloudfareApi,
  endpointSendOTP,
  endpointDownloadWishes,
  endpointDownloadPhotos,
  endpointDownloadPromptpay
}